.preloading svg {
  display: none;
}

#preloader {
  position: fixed;
  z-index: 9999999999999999;
  background-color: #FFF;
  color: #000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

#preloader.hidden {
  display: none;
}

#preloader.invisible {
  visibility: hidden;
}

#preloader svg {
  fill: #00BAFF;
}

.app-loading-placeholder {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;

  .loader-text {
    font-family: 'Roboto', 'Helvetica', sans-serif;
    font-weight: 300;
    width: 100%;
    height: 40px;
    position: absolute;
    top: -120px;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;
    font-size: 24px;
    color: #7990A1;
    margin: auto;
    padding: 0 30px;
  }

  .loader::before,
  .loader::after,
  .loader {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    animation: load 1.8s infinite ease-in-out;
    animation-fill-mode: both;
  }

  .loader {
    color: #E2E2E2;
    font-size: 20px;
    margin: auto;
    position: absolute;
    top: -120px;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateZ(0);
    animation-delay: -0.16s;
  }

  .loader::before {
    left: -3.5em;
    animation-delay: -0.32s;
  }

  .loader::after {
    left: 3.5em;
  }

  .loader::before,
  .loader::after {
    content: '';
    position: absolute;
    top: 0;
  }

  @keyframes load {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }
}
