*,
*::before,
*::after {
  box-sizing: border-box;
}

:focus {
  outline: none;
}

html,
body {
  min-width: 320px;
}

body {
  background: #F2F4F7;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

hr,
button {
  text-align: left;
  border: none;
  background: none;
  padding: 0;
}

button {
  padding: 0;
}
