html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
}

body {
  font-family: 'Roboto', 'Helvetica', sans-serif;
  font-weight: 400;
  letter-spacing: .018em;
  font-size: 100%;
  color: #000;
}

a,
.app-link {
  color: #00BAFF;
  cursor: pointer; //added due to strange behaviour with rich links
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
    color: #00BAFF;
  }
}

p {
  font-weight: 400;
}

em {
  font-style: normal;
}

strong {
  font-weight: 700;
}
